@media (min-width: 600px) {
  .modules-page .solution {
    flex-basis: calc(33.33% - 2em);
  }
}
.modules-page .solution {
  background-color: #f8f9fa;
  box-shadow: none;
  margin: 0.1em;
}
