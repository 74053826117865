.testimonials {
  background-color: #e1f8e6;
}
.testimonials .carousel {
  display: flex;
  flex-direction: column-reverse;
}
.testimonials .carousel-indicators {
  position: static;
}
.testimonials .carousel-caption {
  position: static;
  color: inherit;
}
.testimonials .carousel-indicators button {
  background-color: #2b8724;
}
