.service {
  flex: 0 0 calc(33.33% - 30px);
  padding: 15px;
}
.service img {
  width: 100%;
  height: 233px;
}
/* .services-header {
  font-size: 3.5rem;
  margin-bottom: 0pt;
}
.services-subheading {
  font-size: 1.25rem;
  color: rgb(19, 20, 20);
} */
.services-container {
  display: inline-flex;
  flex-wrap: wrap;
}

@media (min-width: 1141px) {
  .service-main-img {
    background-position-y: calc(100% + 20px);
  }
}

@media (max-width: 1140px) {
  .service {
    flex: 0 0 calc(50% - 30px);
  }
  .service img {
    height: 233px;
  }
  .service-main-img {
    background-position-y: calc(50%);
  }
}

@media (max-width: 600px) {
  .service {
    flex: 0 0 calc(100% - 30px);
  }
  .service-main-img {
    background-position-y: calc(50%);
    height: 30vh;
    width: 100%;
    justify-content: center;
    display: inline-flex;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.service-heading {
  font-size: 30px;
}
.service-main-img {
  background-position-x: center;
  height: calc((2 / 3) * 50vh + 94px);
  width: 100%;
  justify-content: center;
  display: inline-flex;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-description {
  font-size: 1rem;
  color: rgb(125, 130, 133);
}
