.news-item {
  text-align: left;
}
.news-credit {
  color: grey;
  font-size: smaller;
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.news-credit > span {
  padding: 0 0.5em;
}
.news-credit .credit i {
  padding-right: 0.2em;
}
a .news-item {
  color: initial !important;
  text-decoration: none !important;
}
.news-item.article {
  padding-bottom: 1em;
}
.news-content > a {
  text-decoration: none !important;
}
.news-tags {
  color: grey;
  font-size: smaller;
}

.news-preview {
  background-color: white;
  padding: 1em;
  padding-bottom: 2em;
  margin: 0.5em 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
}
.news-preview .text-preview {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.news-item .ib-button,
.news-preview .ib-button {
  color: white;
  background-color: #2b8724;
  border: none;
}
.banner-container.news-banner,
.news-banner .banner-image {
  max-height: 200px;
}
.news-banner .banner-image {
  filter: contrast(0.5);
}

@media (min-width: 600px) {
}
@media (max-width: 599px) {
  .news-banner > h1 {
    font-size: 8vw;
    padding: 0;
  }
  .news-banner > h2 {
    font-size: 3vw;
  }
}
