@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes expand {
  from {
    transform: scaleY(0);
    transform-origin: top center;
  }
  to {
    transform: scaleY(100%);
    transform-origin: top center;
  }
}
@keyframes collapse {
  from {
    transform: scaleY(100%);
    transform-origin: top center;
  }
  to {
    transform: scaleY(0);
    transform-origin: top center;
  }
}
@keyframes shrink {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
