.solution-container {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  background-color: inherit;
  flex-flow: row wrap;
}

.solution-read-more,
.solution-read-more a {
  font-weight: bold;
  padding: 0;
  list-style: none;
}

.solution-container .accordion-button {
  background-color: pink;
}

.solution-header {
  font-size: 1.75rem;
  font-weight: bold;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
}
.solution > div {
  padding: 16px;
}

@media (min-width: 992px) {
  .solution-container {
    max-width: 1200px;
  }
  .solution {
    flex-basis: calc(33.33% - 2em);
  }
  .solution-container-header {
    font-weight: bold;
    font-size: 40px;
  }
}
@media (max-width: 991px) {
  .solution-container {
  }
  .solution-description {
    /* display: none; */
  }
  .solution {
    flex-basis: calc(50% - 1em);
  }
  .solution.full {
    flex-basis: 100% !important;
  }
}

.solution {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  flex-shrink: 1;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
}
.industry-page .solution {
  margin: 1em;
}
.solution-icon,
.solution i {
  font-size: 4em;
}
.solution a,
.solution a:visited,
.solution a:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  padding: 1em;
}

.solution .solution-list-item {
  text-align: left;
}
