.portfolio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.portfolio-tile {
  width: 100%;
  justify-content: center;
  background-size: cover;
  display: flex;
  flex-flow: column;
  color: white;
}
.tile-hover {
  position: absolute;
  display: flex;
}
.portfolio-container > a {
  display: inline-flex;
  text-decoration: none;
  color: white;
}
.tile-hover {
  animation-name: fade-out;
  animation-duration: 0.5s;
  background-color: #2b8724;
  animation-fill-mode: forwards;
}
.tile-hover:hover {
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 700px) {
  .portfolio-container > a {
    flex-basis: 33.33%;
  }
  .portfolio-tile {
    height: 20vw;
  }
  .tile-hover {
    width: 33.33%;
  }
}
@media (max-width: 699px) and (min-width: 500px) {
  .portfolio-container > a {
    flex-basis: 50%;
  }
  .portfolio-tile {
    height: 30vw;
  }
  .tile-hover {
    width: 50%;
  }
}
@media (max-width: 499px) {
  .portfolio-container > a {
    flex-basis: 100%;
  }
  .portfolio-tile {
    height: 50vw;
  }
  .tile-hover {
    width: 100%;
  }
}
