.pagination-widget {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}
.pagination-widget > div:hover {
  background-color: #bbffb6;
  cursor: pointer;
}
.pagination-widget > div {
  background-color: white;
  color: black;
  padding: 0.5em;
  margin: 0 0.25em;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
}
.pagination-widget .active {
  background-color: #2b8724;
  color: white;
}
.pagination-widget .hidden {
  display: none;
}
.pagination-widget .disabled:hover,
.pagination-widget .disabled {
  background-color: #6f8a6d;
  color: white;
}
