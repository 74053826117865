.benefits-container {
  background-color: #2b8724;
  color: rgb(255, 255, 255);
  /* width: 100%; */
  padding: 15px;
}
.benefits-container > h1 {
  font-size: 72px;
  font-weight: 300;
  margin-bottom: 0.5rem;
}
.benefits-container ul {
  margin-left: 40px;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}
.benefits-container .page-content > div {
  display: inline-flex;
  justify-content: center;
}
.benefits-container img {
  height: fit-content;
}
.request-demo {
  height: 325px;
  width: 100%;
  justify-content: center;
  display: inline-flex;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 1000px) {
  .benefits-container img {
    max-width: 500px;
  }
}
@media (max-width: 999px) {
  .benefits-container img {
    max-width: 100%;
  }
  .benefits-container .page-content > div {
    flex-direction: column;
  }
  .benefits-container ul {
    margin-left: 0;
  }
}
