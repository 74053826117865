.home-container {
  background-color: #e1f8e6;
}
.what-we-do-container {
  background-color: #2b8724;
  padding-bottom: 1em;
  color: white;
}
.home-container .solution {
  margin: 0.5em;
}

.home-container .solution-description {
  color: #868e96;
  font-size: 16px;
}

.base-modules {
  background-color: white;
}
.base-modules .page-content {
  flex-direction: column;
}
.base-modules .page-content > div {
  flex-basis: calc(50% - 2rem);
  padding: 2rem;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.base-modules .module {
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 1em;
}
.base-modules .module strong {
  padding-left: 15px;
}

@media (min-width: 700px) {
  .home-container .solution {
    flex-basis: calc(25% - 2em);
  }
  .home-container .solution-header {
    font-size: 1.75rem;
  }
  .base-modules .module {
    flex-basis: 50%;
  }
  .base-modules .module ul {
    padding-right: 50px;
  }
}
@media (max-width: 699px) {
  .home-container .solution {
    flex-basis: calc(50% - 2em);
  }
  .home-container .solution-header {
    font-size: 1em;
  }
  .home-container .solution-container {
    flex-wrap: wrap;
  }
  .base-modules .module {
    flex-basis: 100%;
  }
}
