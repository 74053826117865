.footer {
  background-color: #222222;
  color: rgb(255, 255, 255);
  padding: 50px 0;
}

.footer .solution-container {
  padding-left: 15px;
  padding-right: 15px;
  display: inline-flex;
}

.footer .solution-icon {
  color: inherit;
}

.footer .solution {
  background-color: inherit;
  color: inherit;
  box-shadow: none;
  flex: 0 0 calc(33.33% - 62px);
}
.footer .solution-description {
  display: inherit;
}
@media (max-width: 699px) {
  .footer .solution {
    flex: 0 0 calc(100% - 62px);
  }
  .footer .solution-description {
    font-size: 1rem;
  }
  .footer h1 {
    font-size: 2rem;
  }
  .footer .solution-icon {
    font-size: 2rem;
  }
}
@media (min-width: 700px) {
  .footer .solution {
    flex-basis: calc(33.33% - 32px);
  }
}
