.App {
  background-color: #eef4f7;
  display: flexbox;
  text-align: center;
}
.hidden {
  display: none;
}
.page-content {
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2em;
}
.page-header {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0pt;
}
.page-subheading {
  font-size: 1.25rem;
  font-weight: 300;
  padding: 20px 0;
}
.banner-container {
  display: grid;
  align-content: center;
  overflow: hidden;
}
.banner-image {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 1;
}
.banner-container h1 {
  font-weight: bold;
  font-family: Roboto, sans-serif;
  z-index: 2;
}
.banner-container h2 {
  font-family: Open Sans, Helvetica Neue, Arial, sans-serif;
  z-index: 2;
}
.banner-container h1,
.banner-container h2 {
  color: white;
  text-align: center;
  vertical-align: middle;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
}
.ironbark-green {
  color: #2b8724;
}
h1 {
  margin-block-start: 0;
}
.ib-button {
  border-style: solid;
  border-radius: 20px;
  border-color: white;
  background-color: rgb(23, 162, 184);
  color: white;
  padding: 0.5em 1em;
  align-self: center;
  width: fit-content;
  text-decoration: none;
  animation-name: shrink;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.ib-button:hover {
  animation-name: grow;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.custom-content-text {
  text-align: left;
}
.background-white {
  background-color: white;
}
@media (min-width: 600px) {
  .banner-container h1 {
    font-size: 56px;
  }
  .banner-container h2 {
    font-size: 1.75rem;
  }
  .content {
    margin-top: clamp(63px, 10vh, 94px);
  }
  .banner-container,
  .banner-image {
    height: calc((2 / 3) * 50vh);
  }
}
@media (max-width: 599px) {
  .content {
    margin-top: clamp(63px, 10vh, 94px);
  }
  .banner-container h2,
  .banner-container h1 {
    margin-block: 0;
    padding-block: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-block-end: 0;
    padding-block-start: 0;
  }
  .banner-container h1 {
    margin-top: 1em;
    padding: 0em 0em 0.2em 0em;
    font-size: 7vw;
  }
  .banner-container h2 {
    margin: 0;
    padding: 0 1em;
    font-size: 4vw;
  }
  .page-content,
  .custom-content-text {
    padding: 0 1em;
  }
  .banner-container {
    min-height: fit-content;
  }
  .banner-container,
  .banner-image {
    height: 30vh;
  }
}
