.features-container {
  color: white;
  background-color: #2b8724;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 5rem;
}
.features-container .intro h1 {
  font-size: 5.5rem;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  margin-block-end: 0;
}
.features-container .intro p {
  font-size: 24px;
}
.features-container .page-content {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.feature {
  display: inline-flex;
  flex-direction: row;
  padding: 10px;
}
.features-container .feature-icon {
  color: #ffcc1e;
  align-self: center;
  font-size: 40px;
}
.features-container .feature-description {
  color: rgba(255, 255, 255, 0.6);
}
.feature-label {
  display: inline-flex;
  flex-direction: column;
  padding-left: 2rem;
}
.feature-label h3,
.feature-label p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0.2rem;
  text-align: left;
}

.development-container {
  padding: 0 30px;
}
.development-container .page-content {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 30px 0;
}
.development-container .project {
  background-color: white;
  margin: 0 15px;
  padding: 15px;
}
.development-container .project img {
  border-radius: 50%;
  width: 100%;
  vertical-align: middle;
  height: 180px;
  width: 180px;
}
@media (min-width: 1000px) {
  .feature {
    flex-basis: calc(33.33% - 60px);
  }
  .development-container .project {
    flex-basis: calc(33.33% - 60px);
  }
  .development-container .page-content {
    flex-direction: row;
  }
}
@media (max-width: 999px) and (min-width: 700px) {
  .feature {
    flex-basis: calc(50% - 60px);
  }
  .development-container .project {
    flex-basis: calc(50% - 60px);
    margin-bottom: 1em;
  }
  .development-container .page-content {
    flex-direction: row;
  }
}
@media (max-width: 699px) {
  .feature {
    flex-basis: 100%;
  }
  .development-container .project {
    flex-basis: calc(100%);
    margin-bottom: 1em;
  }
  .development-container .page-content {
    flex-direction: column;
  }
}
