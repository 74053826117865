.nav-container {
  display: flex;
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 999;
  width: 100%;
  min-height: 63px;
  max-height: 94px;
}
.nav-container img {
  height: -webkit-fill-available;
  width: auto;
  display: inline-block;
  max-height: 68px;
}
.log-in {
  margin-left: 1rem;
}
.nav-container .logo {
  font-weight: bold;
}
.nav-container .container {
  max-width: 100% !important;
}
.nav-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.hash-link,
.hash-link:hover {
  color: initial;
  text-decoration: none;
}
