.contact-page {
  background-color: #e1f8e6;
}
.contact-page .page-container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.contact-info {
  max-width: 500px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-top: 1.5rem;
}
.contact-info > span {
  padding: 0.5em 0;
  border-bottom: solid;
  border-color: rgb(222, 226, 230);
  border-bottom-width: 1px;
  display: inline-flex;
  width: 100%;
}
.contact-info span i {
  margin-right: 10px;
}
.contact-info span i::after {
  padding-right: 5px;
}
.ironbark-address-img {
  height: 500px;
  width: 100%;
  justify-content: center;
  display: inline-flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-form {
  max-width: 500px;
  display: inline-flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-left: 18px;
}
.contact-form > h1 {
  text-align: left;
  font-family: "Merriweather", "Helvetica Neue", Arial, sans-serif;
}
.contact-info a,
.contact-info a:active,
.contact-info a:hover,
.contact-info a:visited {
  text-decoration: none;
  color: initial;
}
.contact-form form,
.contact-form fieldset {
  border: none;
}
.contact-form label {
  display: flex;
  margin: 0.5rem 0;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
  resize: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.contact-form .submit-button {
  width: 100%;
  height: 50px;
  color: white;
  background-color: #2b8724;
  padding: 0.375rem 0.75rem;
  margin: 0.5rem 0;
  border: none;
  font-size: 1.5rem;
}
.contact-form .submit-button:hover {
  background-color: #ee4b28;
  cursor: pointer;
}
.contact-form .checkbox-container {
  display: flex;
  flex-direction: row;
}
.contact-form .checkbox-container .checkbox-checkmark {
  width: auto;
}
.contact-form .checkbox-container .checkbox-label {
  text-align: left;
}
/* .submit-button > i {
  color: white;
} */
@media (min-width: 700px) {
  .contact-form,
  .contact-info {
    flex-basis: 50%;
  }
}
@media (max-width: 699px) {
  .contact-form,
  .contact-info {
    flex-basis: calc(100% - 24px);
  }
  .contact-info {
    padding: 0 18px;
    padding-bottom: 2em;
  }
  .contact-form {
    max-width: calc(100% - 24px);
  }
}
